import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/common.less'
import './style/dialog.less'
// import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {
	Popover
} from 'element-ui';
import 'animate.css'
import http from '@/utils/HttpUtils' // 封装的请求
import '@/utils/interceptors.js' // 拦截器
import VideoPlayer from 'vue-video-player'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.prototype.$http = http
Vue.use(Popover);
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
