import Vue from 'vue'
import Vuex from 'vuex'
import {
	Message
} from 'element-ui';
import router from '@/router';
import http from '@/utils/HttpUtils';
Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		webSiteObj: null, //站点信息
		webFooterInfo: null, //底部数据信息
		menuList: [], //站点导航栏
		isLogin: localStorage.getItem('token') ? true : false,
		token: localStorage.getItem('token') || '',
		payByList: JSON.parse(localStorage.getItem('payBy')) || [], //当前机构支付方式
		userInfo: JSON.parse(localStorage.getItem('userInfo')) || [],
		avatar: '', //用户头像
		answerInfo: [], //答题卡
		navcolor: {}
	},
	mutations: {
		//设置底部数据信息
		SET_FOOTER(state, data) {
			state.webFooterInfo = data
		},
		// 设置站点信息
		SET_WEBSITE(state, data) {

			state.webSiteObj = data;
		},
		// 设置站点导航栏
		SET_MENU(state, data) {
			state.menuList = data;
		},
		// 设置token
		SET_TOKEN(state, token) {
			state.token = token;
		},
		//设置导航栏颜色
		SET_COLOR(state, data) {

			state.navcolor = data
		},
		// 设置登录状态
		SET_LOGIN(state, Boolean) {
			state.isLogin = Boolean
		},
		// 设置当前机构支付方式
		SET_PAYBY(state, data) {
			state.payByList = data;
			localStorage.setItem('payBy', JSON.stringify(data));
		},
		// 设置用户信息
		SET_USER_INFO(state, data) {
			state.userInfo = data
		},
		// 设置用户头像
		SET_AVATAR(state, data) {
			state.avatar = data
		},
		// 储存答题情况
		SAVA_ANSWER(state, data) {
			state.answerInfo = data;
		},
	},
	getters: {
		filterUserInfo: state => {
			if (!state.userInfo) return
			return state.userInfo.map(item => {
				let list = [];
				if (item.type == 2) {
					switch (item.sourse) {
						case 2:
							let arr = item.list;
							arr.forEach((item, index) => {
								item.children.forEach((jtem, Jindex) => {
									delete jtem.children
								})
							});
							list = arr;
							break;
						case 3:
							item.dto.forEach((item, index) => {
								list.push({
									value: index,
									label: item
								})
							})
							break;
					}
				}
				return {
					choose: item.choose,
					label: item.label.split(":")[0],
					key: item.label.split(":")[1],
					value: item.value,
					type: item.type,
					sourse: item.sourse,
					list
				}
			})
		},
		doneUserInfo: (state, getters) => {
			let data = getters.filterUserInfo;
			let obj = {};
			for (let key in data) {
				obj[data[key].key] = data[key].value
			}
			return obj
		},
	},
	actions: {
		// 登录
		Login({
			commit
		}, userInfo) {
			return new Promise((resolve, reject) => {
				http.post('loginApi', userInfo).then(res => {
					if (res.code == 200) {
						const data = res.data;
						// 储存token
						commit('SET_TOKEN', data.token);
						localStorage.setItem('token', data.token);
						// 登录状态为true
						commit('SET_LOGIN', true);
						//	储存用户信息
						commit('SET_USER_INFO', data.stu);
						localStorage.setItem('userInfo', JSON.stringify(data.stu));
						// if (data.isUpdate == 0) {
						// 	router.push({
						// 		path: '/personalCenter',
						// 		query: {
						// 			isUpdate: 0
						// 		}
						// 	})
						// 	Message.error('密码过于简单，请修改')
						// } else {
						// 	router.push({
						// 		path: '/',
						// 		query: {
						// 			isUpdate: 1,
						// 			index: 1
						// 		}
						// 	})
						// }
						resolve(data)
					} else {
						resolve(false)
						Message.error(res.message)
					}
					
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取头像
		getAvatar({
			commit
		}) {
			return new Promise((resolve, reject) => {
				http.post('queryLoginPhoto').then(res => {
					if (res.code == 200) {
						//	储存用户头像
						commit('SET_AVATAR', res.data || '');
						// localStorage.setItem('avatar', res.data);
					}
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 退出登录
		LoginOut({
			commit
		}) {
			return new Promise((resolve, reject) => {
				http.post('loginOutApi').then(() => {
					localStorage.removeItem('isUpdate')
					// 清除token
					commit('SET_TOKEN', '')
					localStorage.removeItem('token');
					// 登录状态为false
					commit('SET_LOGIN', false);
					// 清除用户信息
					commit('SET_USER_INFO', '');
					localStorage.removeItem('userInfo');
					// 清除用户头像
					commit('SET_AVATAR', '');
					localStorage.removeItem('avatar');
					// 回到首页
					router.push({
						path: '/login',
						// query: {
						// 	isUpdate: 1,
						// 	index: 1
						// }
					})
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		}
	},
	modules: {}
})

export default store
