import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决路由多次点击会报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// VueRouter.afterEach((to, from, next) => {

//   document.body.scrollTop = 0;

//   document.documentElement.scrollTop = 0;

// });

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('../views/index.vue')
	},
	{
		path: '/personalCenter',
		name: 'personalCenter',
		component: () => import('../views/personalCenter/index.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue')
	},
	{
		path: '/retrievePassword',
		name: 'retrievePassword',
		component: () => import('../views/login/retrievePassword.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/login/register.vue')
	},
	{
		path: '/authoritativeTeachers',
		name: 'authoritativeTeachers',
		component: () => import('../views/authoritativeTeachers/index.vue')
	},
	{
		path: '/noticeCenter',
		name: 'noticeCenter',
		component: () => import('../views/noticeCenter/index.vue')
	},
	{
		path: '/noticeCenter/noticeDetail',
		name: 'noticeDetail',
		component: () => import('../views/noticeCenter/noticeDetail.vue')
	},
	{
		path: '/trainingProgram',
		name: 'trainingProgram',
		component: () => import('../views/trainingProgram/index.vue')
	},
	{
		path: '/trainingProgram/courseDetails',
		name: 'courseDetails',
		component: () => import('../views/trainingProgram/courseDetails.vue')
	},
	{
		path: '/trainingProgram/applyPage',
		name: 'applyPage',
		component: () => import('../views/trainingProgram/applyPage.vue')
	},
	{
		path: '/trainingProgram/courseViewing',
		name: 'courseViewing',
		component: () => import('../views/trainingProgram/courseViewing.vue')
	},
	{
		path: '/trainingProgram/paymentOrder',
		name: 'paymentOrder',
		component: () => import('../views/trainingProgram/paymentOrder.vue')
	},
	{
		path: '/trainingProgram/paymentResults',
		name: 'paymentResults',
		component: () => import('../views/trainingProgram/paymentResults.vue')
	},
	{
		path: '/trainingProgram/orderSumbit',
		name: 'orderSumbit',
		component: () => import('../views/trainingProgram/orderSumbit.vue')
	},
	{
		path: '/collectiveRegistration',
		name: 'collectiveRegistration',
		component: () => import('../views/collectiveRegistration/index.vue')
	},
	{
		path: '/examination/finalTest',
		name: 'finalTest',
		component: () => import('../views/examination/finalTest.vue')
	},
	{
		path: '/examination/viewTest',
		name: 'viewTest',
		component: () => import('../views/examination/viewTest.vue')
	},
	{
		path: '/examination/afterClassTest',
		name: 'afterClassTest',
		component: () => import('../views/examination/afterClassTest.vue')
	},
	{
		path: '/examination/answerSituation',
		name: 'answerSituation',
		component: () => import('../views/examination/answerSituation.vue')
	},
	{
		path: '/personalCenter/iwrongQuestion',
		name: 'answerSituation',
		component: () => import('../views/personalCenter/i-wrongQuestion.vue')
	},
	{
		path: '/live',
		name: 'live',
		component: () => import('../views/live/index.vue')
	},
	{
		path: '/liveInfo',
		name: 'liveInfo',
		component: () => import('../views/live/liveInfo.vue')
	},
	{
		path: '/livedetails',
		name: 'livedetails',
		component: () => import('../views/live/livedetails.vue')
	},
	{
		path: '/liveViews',
		name: 'liveViews',
		component: () => import('../views/live/liveViews.vue')
	},
	{
		path: '/richText',
		name: 'richText',
		component: () => import('../views/richText/index.vue')
	},
]


const router = new VueRouter({
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})
router.beforeEach((to, from,next) => {
	const isUpdata = localStorage.getItem('isUpdate')
	console.log(to);
			 if(isUpdata && to.path !== '/personalCenter') {
				next({ path: 'personalCenter' ,	
						query: {
							isUpdate: 0
						}})
			 } else {
				 next()
			 }
	})
export default router