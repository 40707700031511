import axios from 'axios'
import qs from 'qs'
import api from '@/api' // 请求的接口

export default {
	loginSubmit(url, params) {
		return new Promise((resolve, reject) => {
			axios.post(api[url], qs.stringify(params))
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err.data)
				})
		})
	},
	post(url, params) {
		return new Promise((resolve, reject) => {
			axios.post(api[url], qs.stringify(params))
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err.data)
				})
		})
	},
	post2(url, params) {
		return new Promise((resolve, reject) => {
			axios.post(api[url], params)
				.then(res => {
					resolve(res.data)
				})
				.catch(err => {
					reject(err.data)
				})
		})
	},
	get(url, params) {
		return new Promise((resolve, reject) => {
			axios.get(api[url], {
				params: params
			}).then(res => {
				resolve(res.data)
			}).catch(err => {
				reject(err.data)
			})
		})
	},
	uploadFile(url, params) {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		} // 添加请求头
		return new Promise((resolve, reject) => {
			axios.post(api[url], params, config)
				.then(res => {
					resolve(res.data)
				}).catch(err => {
					reject(err.data)
				})
		})
	}

}
